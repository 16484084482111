* {
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    color: #ececec;
    font-size: 16px;
    font-weight: bold;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-weight: lighter;
    background: rgb(38,40,55);
    background: linear-gradient(165deg, rgba(38,40,55,1) 0%, rgba(0,8,50,1) 47%, rgba(40,56,145,1) 47%, rgba(6,115,186,1) 100%); 
    background-attachment: fixed;
    overflow-x:hidden;
}

.App {
    padding: 25px 10px;
    min-height: 75vh;
}

header {
    text-align: center;
    padding: 25px 10px;
    height: 20vh;
}

header img {
    max-height: 100%;
    max-width: 100%;
}

.match-panel {
    max-width: 630px;
    margin: 0 auto 30px;
}

.panel {
    padding: 30px;
    width: 100%;
    max-width: 600px;
    margin: 30px auto;
    color: rgb(38,40,55);
    text-align: left;
    font-size: 12pt;
    background-color: #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    transition: height .2s linear;
}

h1 {
    margin: 30px auto 0;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-weight: normal;
    color: #fff;
}

.hidden {
    display: none;
}

.container-fluid {
    max-width: 1200px;
}

.panel .row {
    margin-top: 30px;
}

.panel .row:first-child {
    margin-top: 0
}

.panel h2 {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", Arial, Arial, Helvetica, sans-serif;
    font-size: 16pt;
    font-weight: normal;
    color: inherit;
}

.panel h3 {
    padding: 0;
    margin: 0;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-size: 18px;
}

.panel label {
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #0673ba;
}

.panel .help-text {
    margin-left: 15px;
    font-size: 13px;
    color: #666;
    text-transform: initial;
}

.panel input[type ="file"] {
    padding:  0 !important;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    border: none;
    font-size: 13px;
}

.panel input[type="text"]{
    font-size: 15px;
    padding: 0 0 10px;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    border: none;
    border-bottom: solid 1px rgb(38,40,55);
    border-radius: 0;
    outline: none !important;
    color: rgb(38,40,55);
}

.panel ::placeholder {
    color: #aaa;
    opacity: 1; /* Firefox */
 }

.panel input:focus {
    outline: none !important;
    box-shadow: none;
}

.panel .circle-highlight {
    display: block;
    color: #bf1e2e;
    text-align: center;
    text-transform: uppercase;
}

.panel ul {
    padding: 0;
    list-style-type: none;
    display: block;
    width: 100%;
    margin-bottom: 0;
}
.panel li,
.final-rank {
    position: relative;
    clear: both;
    overflow: hidden;
    margin: 0;
    border-top: dotted 2px #ccc;
}

.panel li {
    padding: 15px 40px 15px 0;
    min-height: 56px;
}

.final-rank {
    margin: 15px -15px 0;
    padding: 15px 0 0;
}

.panel li:first-child,
.final-rank:first-child {
    border-top: none;
}

.panel li button {
    position: absolute;
    top: 7px;
    right: 0;
}

.panel li h4 {
    margin: 0;
    font-size: 12pt;
}

.panel li .media-body {
    font-size: 10pt;
}

.panel li .media-left {
    float: left;
}

.panel p {
    margin: 10px 0 0;
}

.panel .author,
.final-rank .description,
.final-rank .win-count {
    margin-top: 0;
    font-size: 10pt;
}

.panel .thumbnail,
.final-rank .thumbnail {
    width: 100%;
    height: auto;
    min-height: 50px;
    padding: 5px;
    background-color: #eee;
    overflow:hidden;
    font-size: 8pt;
    object-fit: cover;
}

.final-rank .thumbnail {
    max-height: 75px;
    max-width: 100%;
}

.media-left .thumbnail {
    max-width: 200px;
}

.final-rank .final-place {
    background: red;
    color: #fff;
    text-align: center;
    display: block;
    height: 40px;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 40px;
    font-weight: bold;
}

.loading-screen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    line-height: 100vh;
    text-align: center;
    font-size: 16pt;
}


/* not mobile */
@media screen and (min-width: 768px) {
    body {
        
    }
}

.h-100 {
    position: relative;
}

.RumbleMenu {
    text-align: center;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}

.ShowdownHolder {
    min-height:60vh;
    margin-right: 0;
    margin-left: 0;
}

.ShowdownCorner {
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: unset;
}

.ShowdownCorner:hover {
    z-index: 1000;
}

.ShowdownCorner > div {
    cursor: pointer;
    z-index: 0;
    height: 100%;
    background-size: cover;
    background-position: center center;
    position: relative;
    transform: scale(1);
    overflow: hidden;
}

@keyframes pulse-blue {
    0% {
        transform: scale(1.05);
        box-shadow: 0 0 0 0 rgba(54, 57, 219, 0.7);
    }

    40% {
        transform: scale(1.4);
    }

    80% {
        box-shadow: 0 0 0 200px rgba(54, 57, 219, 0);
        opacity: 1;
        transform: scale(1.4);
    }

    100% {
        box-shadow: 0 0 0 0px rgba(54, 57, 219, 0);
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes pulse-red {
    0% {
        transform: scale(1.05);
        box-shadow: 0 0 0 0 rgba(219, 54, 57, 0.7);
    }

    40% {
        transform: scale(1.4);
    }

    80% {
        box-shadow: 0 0 0 200px rgba(219, 54, 57, 0);
        opacity: 1;
        transform: scale(1.4);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(219, 54, 57, 0);
        opacity: 0;
    }
}

.BlueCorner > div {
    background-color: rgba(54,57,219,1);
    box-shadow: 0 0 0 0 rgba(54,57,219,1);
}

.RedCorner > div {
    background-color: rgba(219, 54, 57, 1);
    box-shadow: 0 0 0 0 rgba(219, 54, 57, 1);
}

.BlueCorner .animate {
    animation: pulse-blue 1s;
}

.RedCorner .animate {
    animation: pulse-red 1s;
}

.ShowdownCorner .animate {
    z-index: 1000;
}

.ShowdownContent {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2em;
    background: rgba(0,0,0,0.3);
}

@media screen and (max-width: 800px) {

    .ShowdownContent {
        padding: 1em;
    }
    
    .ShowdownCorner {
        height: 30vh;
        width: 100%;
        transition: height 2s ease-in-out;
        box-shadow: 5px 5px 5px rgba(0,0,0,0);
    }

    .RedCorner {
        top: 0;
    }

    .BlueCorner {
        bottom: 0;
    }
}

.ShowdownCorner:focus {
    transform: scale(3);
}

@media screen and (min-width: 800px) {
    .ShowdownCorner {
        height: 60vh;
        width: calc(50% - 10px);
        transition: width 2s ease-in-out;
        top: 0;
        box-shadow: 5px 5px 5px rgba(0,0,0,0);
    }

    .RedCorner {
        left: 0;
        margin-right: 10px;
    }

    .BlueCorner {
        right: 0;
        margin-left: 10px;
    }
}

.RumbleListPage {
    text-align: center;
}

.CustomListNewListItemHolder {
    display: flex;
}

.CustomListNewListItemHolder input {
    flex-shrink: 1;
}

.categorySummary {
    display: flex;
    text-align: left;
    padding: 30px;
    width: 100%;
    max-width: 600px;
    margin: 30px auto;
    color: rgb(38,40,55);
    text-align: left;
    font-weight: lighter;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-size: 12pt;
    background-color: #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    transition: height .2s linear;
}

.thumbnail {
    display: inline-block;
    flex-shrink:0;
    width: 8em;
    height: 8em;
}

.categoryDetails {
    width: 100%;
    flex-shrink: 1;
}

.modal {
    color: black;
}

.thumnailButtonHolder {
    position: absolute;
    top: 0.5rem;
    right: 1.25rem;
}

.thumnailButtonHolder button {
    position: relative !important;
}

.colorSelectPreview {
    border-radius: 4px;
    border: solid gray 1px;
}
